<script setup>
const { t } = useI18n();
const program = [
  {
    title: t("programme.competition.title"),
    id: "Mezinárodní soutěž",
    id_en: "International Competition",
    date: t("programme.competition.date"),
    location: t("programme.competition.location"),
    text: t("programme.competition.text"),
  },
  {
    title: t("programme.installation.title"),
    id: "VR Instalace: Za skleněný strop",
    id_en: "",
    date: t("programme.installation.date"),
    location: t("programme.installation.location"),
    text: t("programme.installation.text"),
  },
  {
    title: t("programme.special.title"),
    id: "Speciální program",
    id_en: "",
    date: t("programme.special.date"),
    location: t("programme.special.location"),
    text: t("programme.special.text"),
  },
  {
    title: t("programme.schools.title"),
    id: "",
    id_en: "",
    date: t("programme.schools.date"),
    location: t("programme.schools.location"),
    text: t("programme.schools.text"),
  },
];
</script>

<template>
  <section class="program white">
    <div class="mw-50">
      <h2 class="program--header m-bottom-4">
        <span class="program--title center">{{ t("programme.title-01") }}</span>
        <span class="program--title left">{{ t("programme.title-02") }}</span>
        <span class="program--title right">{{ t("programme.title-03") }}</span>
      </h2>
      <div class="grid">
        <div class="w-50">
          <div class="m-bottom-2">
            <img src="/images/program.webp" class="radius" />
          </div>
          <button class="block m-bottom-8">
            <NuxtLink :to="localePath('programme')">{{
              t("programme.button")
            }}</NuxtLink>
          </button>
        </div>

        <div class="w-50">
          <ProgramItem
            v-for="item in program"
            :title="item.title"
            :subtitle="item.date"
            :text="item.text"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<style>
/*program*/

.program--title {
  display: block;
}
</style>
