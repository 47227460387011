<script setup>
useHead({
  script: [
    {
      type: "text/javascript",
      innerHTML: `(function(){
var i,e,d=document,s="script";i=d.createElement("script");i.async=1;i.charset="UTF-8";
i.src="https://cdn.curator.io/published/b71e90f3-13dc-4185-9b30-7680ac8079fc.js";
e=d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
})();`,
      body: true,
    },
  ],
});
</script>

<template>
  <div id="curator-feed-default-feed-layout" class="bg-black"></div>
  <a href="https://curator.io" target="_blank" class="crt-logo crt-tag"
    >Powered by Curator.io</a
  >
</template>

<style scoped>
h2 {
  margin-bottom: 0rem;
  margin-top: 3rem;
}

.crt-widget.crt-widget-branded {
  background-color: black;
  padding-bottom: 0;
  border-bottom: white 2px solid;
}
</style>
