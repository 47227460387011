<script setup>
const { t } = useI18n();
</script>

<template>
  <section class="full intro white">
    <div class="center mw-40">
      <img src="/images/logo/art_vr.svg" />
      <h6>{{ t("home.logo.subtitle") }}</h6>
    </div>
    <div class="intro--bottom">
      <div>
        <h6 v-html="t('home.intro.subtitle')"></h6>
        <h4 v-html="t('home.intro.date')"></h4>
      </div>
    </div>
  </section>
</template>

<style>
.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  .intro--bottom {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 2rem;
  }
}
</style>
